import collections from "./templates/index.js";

const state = {
  currentPage: 1,
  epacksSearch: "",
  epacksPerPage: "10",
  unsavedPopupShow: false,
  unsavedEpackageID: null,
  activeComponent: null,
  activeElement: null,
  licensesList: [],
  isSaved: true,
  loading: false,
  synkProcess: false,
  currentEpackage: null,
  currentTemplate: "master_template",
  currentLocale: "ru",
  templatesList: [],
  availableCategoriesList: [],
  usersList: [],
  brands: [],
  epackData: {
    manifest: {
      creationDate: "",
      version: "",
      description: "",
      packageID: "",
      emsId: "constructor",
      author: localStorage.getItem("ttlUserInfo") !== null ? JSON.parse(localStorage.getItem("ttlUserInfo"))["username"] : "",
      tags: "",
      productData: {
        brandName: "",
        productName: "",
        MPN: "",
        EAN: "",
        category: [],
      },
      data: {
        ru: {
          minisite: {},
        },
      },
    },
    components: {
      ru: {
        master_template: [],
        ozon_template: [],
        img_template: [],
      }
    },
    coverData: {},
    usedFonts: [],
  },
  activeSize: "1200px",
  activeType: "desktop",
  sizes: {
    desktop: "1200px",
    laptop: "991px",
    tablet: "768px",
    mobile: "320px",
  },
  fontsSources: [],
  availableFonts: [
    {
      fontFamily: "Arial",
      fontWeight: ["400", "700"],
      fontStyle: ["normal", "italic"],
    },
    {
      fontFamily: "TimesNewRoman",
      fontWeight: ["400", "700"],
      fontStyle: "normal",
    },
  ],
  cover: {
    props: [
      {
        name: "altAttrText",
        nameText: "Alt Attr Text",
        type: "text",
        defaultValue: "Промо обложка",
      },
      {
        name: "buttonText",
        nameText: "Button Text",
        type: "text",
        defaultValue: "Читать описание товара",
      },
      {
        name: "srcDesktop",
        nameText: "Desktop Image",
        type: "file",
        defaultValue: "/placeholders/promo-cover-pdp.png",
        resetValue: "/placeholders/promo-cover-pdp.png",
        recommendedWidth: 3000,
        width:3000,
        height:652,
      },
      {
        name: "srcTablet",
        nameText: "Tablet Image",
        type: "file",
        defaultValue: "/placeholders/promo-cover-pdp.png",
        resetValue: "/placeholders/promo-cover-pdp.png",
        recommendedWidth: 3000,
        width:3000,
        height:652,
      },
      {
        name: "srcMob",
        nameText: "Mobile Image",
        type: "file",
        defaultValue: "/placeholders/promo-cover-mobile-pdp.png",
        resetValue: "/placeholders/promo-cover-mobile-pdp.png",
        recommendedWidth: 800,
        width:656,
        height:800,
      },
    ],
  },
  sectionsParams: collections,
};

export default state;