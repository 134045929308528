import APIConstructor from "../../../services/API/APIConstructor";
import API from "../../../services/API/API";
import router from "../../../router/index";
import {getUniqArrayOfObjects} from "../../../services/Helpers/HelperObject";
// import {listToTree, listToTreeLabeled} from "@/tree";

import { showToastError, showToastSuccess } from '../../../services/Helpers/HelperToast';

import dcopy from "deep-copy";
import * as htmlToImage from "html-to-image";

const actions = {
  changeSize({ state, commit }, type) {
    commit("setActiveType", type);
    commit("setActiveSize", state.sizes[type]);
  },
  setCurrentPage({ commit }, pageNumber) {
    commit("setCurrentPage", pageNumber);
  },
  setEpacksPerPage({ commit }, epacksPerPage) {
    commit("setEpacksPerPage", epacksPerPage);
  },
  setEpacksSearch({ commit }, value) {
    commit("setEpacksSearch", value);
  },
  setCurrentLocale({ state, commit }, locale) {
    commit("setCurrentLocale", locale);
    commit("setCurrentTemplate", "master_template");
    const templatesList = locale !== null ? Object.keys(state.epackData.components[state.currentLocale]) : [];
    commit("setTemplatesList", templatesList);
    commit("updateActiveComponent", null);
    commit("updateActiveElement", null);
  },
  setCurrentTemplate({ commit }, template) {
    commit("setCurrentTemplate", template);
    commit("updateActiveComponent", null);
    commit("updateActiveElement", null);
  },
  loadEpack({ state, dispatch, commit }) {
    const manifest = {
      creationDate: "",
      version: "",
      description: "",
      packageID: "",
      emsId: "constructor",
      author: JSON.parse(localStorage.getItem("ttlUserInfo"))["username"],
      tags: "",
      productData: {
        brandName: "",
        productName: "",
        MPN: "",
        EAN: "",
        categoriesList: [],
      },
      data: {
        ru: {
          minisite: {},
        },
      },
    };
    commit("setCoversData", {});
    commit("setCurrentTemplate", "master_template");
    commit("updateManifestData", manifest);
    commit("updateComponentsData", { ru: { master_template: [], ozon_template: [], img_template: [] } });
    const localStorageEpack = localStorage.getItem("epackData");
    if (localStorageEpack !== null) {
      const localStorageEpackObj = JSON.parse(localStorageEpack);
      const epackID = localStorageEpackObj.epackID;
      if (epackID === state.currentEpackage) {
        if (!localStorageEpackObj.data.manifest.productData.hasOwnProperty("categoriesList")) {
          localStorageEpackObj.data.manifest.productData.categoriesList = [];
        }
        commit("updateManifestData", localStorageEpackObj.data.manifest);
        let components = localStorageEpackObj.data.components;
        components = clearTemplatesInLocales(components);
        components = addNewProps(components);
        const defaultLocale = Object.keys(components)[0];
        commit("setCurrentLocale", defaultLocale);
        const templatesList = Object.keys(components[state.currentLocale]);
        commit("setTemplatesList", templatesList);
        commit("updateComponentsData", components);
        if (localStorageEpackObj.data.hasOwnProperty('images')) {
          let images = localStorageEpackObj.data.images;
          commit("setImages", images);
        }
        if (localStorageEpackObj.data.hasOwnProperty('coverData')) {
          let coverData = localStorageEpackObj.data.coverData;
          commit("setCoversData", coverData);
        }
        commit("updateIsSavedVal", false);
      } else {
        commit("updateUnsavedEpackageID", epackID);
        commit("updateUnsavedPopupShow", true);
      }
    } else {
      if (state.currentEpackage !== "untitled") {
        commit("updateLoading", true);
        APIConstructor.APIGet(
          `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/epack/${state.currentEpackage}`,
          {},
          (response) => {
            if (response.code === 200) {
              if (!response.manifest.productData.hasOwnProperty("categoriesList")) {
                response.manifest.productData.categoriesList = [];
              }
              commit("updateManifestData", response.manifest);
              let components = response.components;
              components = clearTemplatesInLocales(components);
              components = addNewProps(components);
              const defaultLocale = Object.keys(components)[0];
              commit("setCurrentLocale", defaultLocale);
              const templatesList = Object.keys(components[state.currentLocale]);
              commit("setTemplatesList", templatesList);
              commit("updateComponentsData", components);
              if (response.hasOwnProperty('coverData')) {
                let coverData = response.coverData;
                commit("setCoversData", coverData);
              }
              commit("setImages", response.images);
              commit("updateIsSavedVal", true);
              dispatch("updateUsedFonts");
            } else if (response.code === 402) {
              showToastError(
                response.message,
                this._vm.$toast,
              );
            } else {
              showToastError(
                "Something wrong. <br/> Please try later",
                this._vm.$toast,
              );
            };
            commit("updateLoading", false);
          },
          (error) => {
            if (error.response && error.response.status === 402) {
              showToastError(
                error.response.data.data.message,
                this._vm.$toast,
              );
            } else {
              showToastError(
                "Something wrong. <br/> Please try later",
                this._vm.$toast,
              );
            }
            console.error(error);
            commit("updateLoading", false);
          },
        );
      } else {
        commit("setCurrentLocale", Object.keys(state.epackData.components)[0]);
        commit("setCurrentTemplate", "master_template");
      };
    };
  },
  setActiveComponent({ state, commit }, index) {
    if (state.activeComponent !== index) {

      commit("updateActiveComponent", index);
      commit("updateActiveElement", null);
    }
  },
  setActiveElement({ state, commit }, index) {
    if (state.activeComponent === index.componentIndex) {
      commit("updateActiveElement", index.elementIndex);
    } else {
      commit("updateActiveComponent", index.componentIndex);
      commit("updateActiveElement", null);
    }
  },
  addComponent({ state, commit }, elementName) {
    const componentFromCollectionProps = dcopy(state.sectionsParams[elementName].props);
    let componentProps = {};
    componentFromCollectionProps.map((elem) => {
      if (elem["name"] === "innerElements") {
        const innerElementsList = [];
        elem["defaultValue"].map(innerElem => {
          let componentInnerItem = {
            component: innerElem.component,
            props: {},
          };
          innerElem.props.map(elemInnerProp => {
            componentInnerItem.props[elemInnerProp["name"]] = elemInnerProp["defaultValue"];
          });

          innerElementsList.push(componentInnerItem);
        });
        componentProps[elem["name"]] = innerElementsList;
      } else {
        componentProps[elem["name"]] = elem["defaultValue"];
      }
    });

    const additionComponent = {
      component: elementName,
      props: componentProps,
    };
    const newComponents = dcopy(state.epackData.components);
    let newActiveComponent = state.activeComponent;
    if (newActiveComponent !== null) {
      newActiveComponent++;
      newComponents[state.currentLocale][state.currentTemplate].splice(newActiveComponent, 0, additionComponent);
    } else {
      if (newComponents[state.currentLocale][state.currentTemplate] === undefined) {
        newComponents[state.currentLocale][state.currentTemplate] = [];
        newActiveComponent = 0;
      } else {
        newActiveComponent = newComponents[state.currentLocale][state.currentTemplate].length === 0 ? 0 : newComponents[state.currentLocale][state.currentTemplate].length - 1;
      }
      newComponents[state.currentLocale][state.currentTemplate].push(additionComponent);
    }
    commit("updateActiveComponent", newActiveComponent);
    commit("updateIsSavedVal", false);
    commit("updateComponentsData", newComponents);
    localStorage.setItem("epackData", JSON.stringify({ epackID: state.currentEpackage, data: state.epackData }));
  },
  addElement({ state, commit }) {
    const componentName = state.epackData.components[state.currentLocale][state.currentTemplate][state.activeComponent].component;
    const componentFromCollectionProps = dcopy(state.sectionsParams[componentName].props);

    let elementFromCollection = {};
    componentFromCollectionProps.map(prop => {
      if (prop.name === "innerElements") {
        elementFromCollection = prop.defaultValue[0];
      }
    });
    const elementForAdding = {};
    elementForAdding.component = elementFromCollection.component;
    elementForAdding.props = {};
    elementFromCollection.props.map(prop => {
      elementForAdding.props[prop.name] = prop.defaultValue;
      if (elementForAdding.component === 'Bubble' && (prop.name === 'top' || prop.name === 'left')) {
        elementForAdding.props[prop.name] = Math.floor(Math.random() * 80) + 10;
      }
    });

    const newComponents = dcopy(state.epackData.components);
    const newComponent = newComponents[state.currentLocale][state.currentTemplate][state.activeComponent];

    let newActiveElement = state.activeElement;
    let newActiveComponent = state.activeComponent;
    if (newActiveElement !== null) {
      newActiveElement++;
      newComponent.props.innerElements.splice(newActiveElement, 0, elementForAdding);
    } else {
      newActiveComponent = newElements.length !== 0 ? newElements.length - 1 : 0;
      newComponent.props.innerElements.push(elementForAdding);
    }
    commit("updateComponentsData", newComponents);
    commit("updateActiveElement", newActiveElement);
    commit("updateIsSavedVal", false);
    localStorage.setItem("epackData", JSON.stringify({ epackID: state.currentEpackage, data: state.epackData }));
  },
  copyComponent({ state, commit }, index) {
    const componentsForUpdate = dcopy(state.epackData.components);
    const componentForCopy = componentsForUpdate[state.currentLocale][state.currentTemplate][index];
    componentsForUpdate[state.currentLocale][state.currentTemplate].splice(index, 0, componentForCopy);
    commit("updateComponentsData", componentsForUpdate);
  },
  removeComponent({ state, commit }, index) {
    const componentsForUpdate = dcopy(state.epackData.components);
    componentsForUpdate[state.currentLocale][state.currentTemplate].splice(index, 1);
    let newActiveComponentIndex = state.activeComponent;
    if (index === newActiveComponentIndex) {
      commit("updateActiveElement", null);
    }
    if (index === 0) {
      newActiveComponentIndex = 0;
    } else if (index <= newActiveComponentIndex && index !== 0) {
      newActiveComponentIndex--;
    }
    if (!componentsForUpdate[state.currentLocale][state.currentTemplate].length) {
      newActiveComponentIndex = null;
    }
    commit("updateActiveComponent", newActiveComponentIndex);
    commit("updateComponentsData", componentsForUpdate);
    commit("updateIsSavedVal", false);
    localStorage.setItem("epackData", JSON.stringify({ epackID: state.currentEpackage, data: state.epackData }));
  },
  removeElement({ state, commit }, indexes) {
    const componentsForUpdate = dcopy(state.epackData.components);
    const elementsList = componentsForUpdate[state.currentLocale][state.currentTemplate][indexes.componentIndex].props.innerElements;
    if (elementsList.length > 1) {
      elementsList.splice(indexes.elementIndex, 1);
      componentsForUpdate[state.currentLocale][state.currentTemplate][indexes.componentIndex].props.innerElements = elementsList;
      let newActiveElementIndex = state.activeElement;

      if (indexes.elementIndex === 0) {
        newActiveElementIndex = 0;
      } else if (indexes.elementIndex <= newActiveElementIndex && indexes.elementIndex !== 0) {
        newActiveElementIndex--;
      }
      commit("updateActiveElement", newActiveElementIndex);
      commit("updateComponentsData", componentsForUpdate);
      commit("updateIsSavedVal", false);
      localStorage.setItem("epackData", JSON.stringify({ epackID: state.currentEpackage, data: state.epackData }));

    }
  },
  updateComponentsData({ state, commit }, componentsData) {
    if (JSON.stringify(state.epackData.components) !== JSON.stringify(componentsData)) {
      commit("updateComponentsData", componentsData);
      commit("updateIsSavedVal", false);
      if (state.activeComponent >= state.epackData.components[state.currentLocale][state.currentTemplate].length) {
        commit("updateActiveComponent", null);
        commit("updateActiveElement", null);
      }
      localStorage.setItem("epackData", JSON.stringify({ epackID: state.currentEpackage, data: state.epackData }));
    };
  },
  updateManifestData({ state, commit }, manifestData) {
    if (JSON.stringify(state.epackData.manifest) !== JSON.stringify(manifestData)) {
      commit("updateManifestData", manifestData);
      commit("updateIsSavedVal", false);
    };
  },
  saveEpack({ state, commit }) {
    let filledFields = true;
    const manifest = state.epackData.manifest;
    for (let prop in manifest) {
      if (prop !== "creationDate" && prop !== "packageID" && prop !== "data") {
        if (prop !== "productData") {
          if (manifest[prop].trim() === "") {
            filledFields = false;
          };
        } else {
          for (let dataProp in manifest[prop]) {
            if (Array.isArray(manifest[prop][dataProp])) {
              if (!manifest[prop][dataProp].length) {
                filledFields = false;
              };
            } else {
              if (manifest[prop][dataProp].trim() === "") {
                filledFields = false;

              };
            }
          };
        };
      };
    };

    if (filledFields) {
      if (state.currentEpackage !== "untitled") {
        commit("updateLoading", true);
        APIConstructor.APIPost(
          `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/save/${state.currentEpackage}`,
          state.epackData,
          (response) => {
            if (response.code === 200) {
              commit("updateManifestData", response.manifest);
              commit("updateComponentsData", response.components);
              commit("setImages", response.images);
              commit("updateIsSavedVal", true);

              showToastSuccess(
                "Saved",
                this._vm.$toast,
              );
              localStorage.removeItem("epackData");
            } else {
              showToastError(
                "Something wrong. <br/> Please try later",
                this._vm.$toast,
              );
            };
            commit("updateLoading", false);
          },
          (error) => {
            if(error?.response?.status) {
              if (error?.response?.status === 402) {
                showToastError(
                  `${error.response.data.data.message} <br><a href="${process.env.VUE_APP_CONSTRUCTOR_URL + error.response.data.data.url}" target="_blank" style=" color:#fff; text-decoration: underline;">Link</a>`,
                  this._vm.$toast,
                );
              } else if (error?.response?.status === 400) {
                const details = JSON.parse(error.response.data.detail);
                showToastError(
                  details.data.message,
                  this._vm.$toast,
                );
              }
            } else {
              showToastError(
                "Something wrong. <br/> Please try later",
                this._vm.$toast,
              );
            }
            commit("updateLoading", false);
          },
        );

      } else {
        commit("updateLoading", true);
        APIConstructor.APIPost(
          `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/create`,
          state.epackData,
          (response) => {
            if (response?.code === 200) {
              const epackID = response.manifest.packageID;
              router.push("/panel/builder/" + epackID);
              commit("updateIsSavedVal", true);
              commit("setCurrentEpackId", epackID);
              commit("updateManifestData", response.manifest);
              commit("setImages", response.images);
              showToastSuccess(
                "Saved",
                this._vm.$toast,
              );
              localStorage.removeItem("epackData");
            } else if (response?.code === 402) {
              showToastError(
                response.message,
                this._vm.$toast,
              );
            } else {
              showToastError(
                "Something wrong. <br/> Please try later",
                this._vm.$toast,
              );
            }
            commit("updateLoading", false);
          },
          (error) => {
            if (error?.response?.status === 402) {
              showToastError(
                error.response.data.data.message,
                this._vm.$toast,
              );
            } else {
              showToastError(
                "Something wrong. <br/> Please try later",
                this._vm.$toast,
              );
            }
            commit("updateLoading", false);
          },
        );
      }
    } else {
      showToastError(
        "Please fill all fields of Epack Data",
        this._vm.$toast,
      );
    };
  },
  closeEpack({ commit }, epackID) {
    if (epackID !== "untitled") {
      commit("updateLoading", true);
      APIConstructor.APIPost(
        `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/epack/close/${epackID}`,{},
        (response) => {
          if (response.code === 200) {
            commit("setImages", []);
            commit("updateComponentsData", {});
            // showToastSuccess(
            //   "Successfully loaded to Panel",
            //   this._vm.$toast,
            // );
            commit("updateLoading", false);
            return {success: true};
          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this._vm.$toast,
            );
          }
          commit("updateLoading", false);
          return {success: false};
        },
        (error) => {
          if (error.response.status === 402) {
            showToastError(
              error.response.data.data.message,
              this._vm.$toast,
            );
          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this._vm.$toast,
            );
          }
          commit("updateLoading", false);
          return {success: false};
        },
      );
    }
  },
  updateCoversData({ commit }, data) {
    commit('setCoversData', data);
    commit('updateIsSavedVal', false);
  },
  uploadEpackToPanel({ commit }, {epackID, isOverwriting , license = null}) {
    const token = localStorage.getItem('accessToken');
    if (epackID !== "untitled") {
      const data = {
        token,
        is_overwriting: isOverwriting,
      };
      if(license !== null) {
        data.license = license;
      }
      commit("updateLoading", true);
      return new Promise((resolve, reject) =>APIConstructor.APIPost(
        `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/load/panel/${epackID}`,
        data,
        (response) => {
          if (response.code === 200) {
            showToastSuccess(
              "Successfully loaded to Panel",
              this._vm.$toast,
            );
            commit("updateLoading", false);
            resolve({success: true});
          } else if (error.response.status === 409) {

          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this._vm.$toast,
            );
          }
          commit("updateLoading", false);
          reject({success: false});
        },
        (error) => {
          if (error.response.status === 402) {
            showToastError(
              error.response.data.data.message,
              this._vm.$toast,
            );
          } else if (error.response.status === 409) {

            commit("setLicensesList", error.response.data.data.message);
          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this._vm.$toast,
            );
          }
          commit("updateLoading", false);
          reject({success: false});
        },
      ));
    }
  },
  getUserBrands({commit}) {
    if (JSON.parse(localStorage.getItem('ttlUserInfo')).roles.includes('ROLE_ADMIN')) {
      API.APIGet(`${process.env.VUE_APP_API_URL}/brands/all`, {}, (res) => {
        commit('setUserBrands', res || []);
      });
    } else {
      API.APIGet(`${process.env.VUE_APP_API_URL}/profile/statistics-groups`, {
      }, (res) => {
        const brandLicenses = getUniqArrayOfObjects(res?.flatMap((group) => group.licenses) || []);
        commit('setUserBrands', getUniqArrayOfObjects(brandLicenses.map(brandLicense => brandLicense.brand) || []));
      });
    }
  },
  setCurrentEpackId({ commit }, epackID) {
    commit("setCurrentEpackId", epackID);
  },
  loadFonts({ state, commit }) {
    APIConstructor.APIGet(
      `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/fonts`,
      {},
      (response) => {
        if (response.code === 200) {
          const availableFonts = response.fonts;
          const sortedFonts = availableFonts.sort((a, b) => {
            if (a.fontFamily.toLowerCase() > b.fontFamily.toLowerCase()) {
              return 1;
            }
            if (a.fontFamily.toLowerCase() < b.fontFamily.toLowerCase()) {
              return -1;
            }
            return 0;
          });

          commit("updateFontsSources", sortedFonts);

          const availableFontsFormatted = dcopy(state.availableFonts);
          let indexInArray;
          availableFonts.map((font) => {
            if (!availableFontsFormatted.length) {
              availableFontsFormatted.push(
                {
                  fontFamily: font.fontFamily,
                  fontWeight: [font.fontWeight],
                },
              );
            } else {
              indexInArray = -1;
              availableFontsFormatted.map((elem, index) => {
                if (elem.fontFamily === font.fontFamily) {
                  indexInArray = index;
                }
              });
              if (indexInArray === -1) {
                availableFontsFormatted.push(
                  {
                    fontFamily: font.fontFamily,
                    fontWeight: [font.fontWeight],
                  },
                );
              } else {
                if (availableFontsFormatted[indexInArray].fontWeight.indexOf(font.fontWeight) === -1) {
                  availableFontsFormatted[indexInArray].fontWeight.push(font.fontWeight);
                };
              };
            };
          });
          commit("updateAvailableFonts", availableFontsFormatted);
        };
      },
      () => {
        showToastError(
          "Something wrong. <br/> Please try later",
          this._vm.$toast,
        );
      },
    );
  },
  getCategoriesList({ commit }) {
    API.APIGet(
      `${process.env.VUE_APP_API_URL}/category/tree-for-epackages`,
      // `${process.env.VUE_APP_API_URL}/categories?limit=20000&offset=0`,
      // `https://24dvlp.com/24stream_constructor_api/epack_data.php`,
      {},
      (response) => {
        // const categories = response.map(elem => (elem.name));
        // const uniqueCategories = categories.filter((item, pos) => (categories.indexOf(item) == pos));
        commit("updateCategoriesList", response);
      },
      (error) => {
        showToastError(
          "Something wrong. <br/> Please try later",
          this._vm.$toast,
        );
        commit("updateCategoriesList", []);
      },
    );
  },
  getUsersList({ commit }) {
    APIConstructor.APIGet(
      `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/list/users`,
      {},
      (response) => {
        if (response.code === 200) {

          commit("updateUsersList", response.data);
        } else {
          showToastError(
            "Something wrong. <br/> Please try later",
            this._vm.$toast
          );
        }
      },
      (error) => {
        showToastError(
          "Something wrong. <br/> Please try later",
          this._vm.$toast
        );
      }
    );
  },
  updateUsedFonts({ state, commit }) {
    const actualUsedFonts = [];
    // console.log(state.epackData.components);
    Object.values(state.epackData.components).forEach(locale => {
      Object.values(locale).forEach(template => {
        template.forEach((component) => {
          if (component.props.fontFamily !== undefined && component.props.fontWeight !== undefined) {
            actualUsedFonts.push({
              fontFamily: component.props.fontFamily,
              fontWeight: component.props.fontWeight,
            });
          };
          if (component.props.titleFontFamily !== undefined && component.props.titleFontWeight !== undefined) {
            actualUsedFonts.push({
              fontFamily: component.props.titleFontFamily,
              fontWeight: component.props.titleFontWeight,
            });
          };
          if (component.props.descriptionFontFamily !== undefined && component.props.descriptionFontWeight !== undefined) {
            actualUsedFonts.push({
              fontFamily: component.props.descriptionFontFamily,
              fontWeight: component.props.descriptionFontWeight,
            });
          };
          if (component.props.disclaimerFontFamily !== undefined && component.props.disclaimerFontWeight !== undefined) {
            actualUsedFonts.push({
              fontFamily: component.props.disclaimerFontFamily,
              fontWeight: component.props.disclaimerFontWeight,
            });
          };
        })
      })
    });

    const actualUsedFontsJSON = actualUsedFonts.map(elem => JSON.stringify(elem));
    const actualUsedFontsUniqueJSON = unique(actualUsedFontsJSON);
    const actualUsedFontsUnique = actualUsedFontsUniqueJSON.map(elem => JSON.parse(elem));
    let usedFontsSorted = state.epackData.usedFonts.map((e) => JSON.stringify(e)).sort();
    let actualFontsSorted = actualUsedFontsUnique.map((e) => JSON.stringify(e)).sort();

    if (JSON.stringify(usedFontsSorted) !== JSON.stringify(actualFontsSorted)) {
      commit("updateUsedFonts", actualUsedFontsUnique);
    }
  },
  resetUploadedFile({ state, commit }, name) {
    const componentName = state.epackData.components[state.currentLocale][state.currentTemplate][state.activeComponent].component;
    const componentsNew = dcopy(state.epackData.components);

    const componentFromCollectionProps = dcopy(state.sectionsParams[componentName].props);
    componentFromCollectionProps.forEach((elem) => {
      if (elem.name === "innerElements") {
        elem.defaultValue.forEach(innerElem => {
          innerElem.props.forEach(elemInnerProp => {
            if (elemInnerProp.name === name) {
              componentsNew[state.currentLocale][state.currentTemplate][state.activeComponent].props.innerElements[state.activeElement].props[name] = elemInnerProp.defaultValue;
            }
          });
        });
      } else {
        if (elem.name === name) {
          componentsNew[state.currentLocale][state.currentTemplate][state.activeComponent].props[name] = elem.defaultValue;
        }
      }
    });
    commit("updateComponentsData", componentsNew);
    commit("updateIsSavedVal", false);
    // } else {

    // }
  },
  addLocale({ state, commit }, locale) {
    const componentsNew = dcopy(state.epackData.components);
    if (!componentsNew.hasOwnProperty(locale)) {
      const localeForAdding = state.epackData.components[state.currentLocale].master_template ? dcopy(state.epackData.components[state.currentLocale].master_template) : [];
      // delete localeForAdding.ozon_template;
      // delete localeForAdding.ali_template;
      componentsNew[locale] = {};
      componentsNew[locale].master_template = localeForAdding;
      commit("updateComponentsData", componentsNew);
      const manifest = state.epackData.manifest;
      manifest.data[locale] = {};
      manifest.data[locale].minisite = {}
      manifest.data[locale].minisite.master_template = { contentTypes: ["html", "json", "iframe"] };
      commit("updateManifestData", manifest);
      commit("setCurrentLocale", locale);
      const templatesList = Object.keys(componentsNew[state.currentLocale]);
      commit("setTemplatesList", templatesList);
      commit("updateIsSavedVal", false);
    }

  },
  addTemplate({ state, commit }, template) {
    const componentsNew = dcopy(state.epackData.components);
    if (!componentsNew[state.currentLocale].hasOwnProperty(template)) {

      const templateForAdding = (template !== 'eld_template' && template !== 'ozon_template' && template !== 'tefal_template' && template !== 'moulinex_template' && template !== 'roventa_template' && template !== 'wmf_template'&& template !== 'krups_template') ? dcopy(state.epackData.components[state.currentLocale].master_template) : [];

      componentsNew[state.currentLocale][template] = templateForAdding;
      commit("updateComponentsData", componentsNew);
      const templatesList = Object.keys(componentsNew[state.currentLocale]);
      commit("setTemplatesList", templatesList);
      commit("setCurrentTemplate", template);

      const manifest = state.epackData.manifest;
      manifest.data[state.currentLocale].minisite[template] = { contentTypes: ["html", "json", "iframe"] };
      commit("updateManifestData", manifest);
      commit("updateIsSavedVal", false);
    }
  },
  deleteLocale({ state, commit }, locale) {
    const componentsNew = dcopy(state.epackData.components);
    if (Object.keys(componentsNew).length > 1) {

      delete componentsNew[locale];
      commit("updateComponentsData", componentsNew);
      if (state.currentLocale === locale) {
        commit("setCurrentLocale", Object.keys(componentsNew)[0]);
        const templatesList = Object.keys(componentsNew[state.currentLocale]);
        commit("setTemplatesList", templatesList);
      }
      const manifest = state.epackData.manifest;
      delete manifest.data[locale];
      commit("updateManifestData", manifest);
      commit("updateIsSavedVal", false);
    }
  },
  deleteTemplate({ state, commit }, template) {
    const componentsNew = dcopy(state.epackData.components);
    delete componentsNew[state.currentLocale][template];
    commit("updateComponentsData", componentsNew);
    const templatesList = Object.keys(componentsNew[state.currentLocale]);
    commit("setTemplatesList", templatesList);

    if (state.currentTemplate === template) {
      commit("setCurrentTemplate", Object.keys(componentsNew[state.currentLocale])[0]);
    }

    const manifest = state.epackData.manifest;
    delete manifest.data[state.currentLocale].minisite[template];
    commit("updateManifestData", manifest);
    commit("updateIsSavedVal", false);
  },
  syncComponents({ state, commit }) {
    const newComponents = dcopy(state.epackData.components);
    commit("updateSynkProcess", true);
    if (state.currentTemplate === "ozon_template") {
      const syncedComponents = [];
      newComponents[state.currentLocale].master_template.map(component => {
        const syncedComponent = {};
        switch (component.component) {
          case "Title":
            syncedComponent.component = "OzonTitle";
            syncedComponent.props = {};
            syncedComponent.props.content = component.props.content;
            syncedComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
            syncedComponent.props.fontSize = component.props.fontSize;
            break;
          case "Description":
            syncedComponent.component = "OzonDescription";
            syncedComponent.props = {};
            syncedComponent.props.content = component.props.content;
            syncedComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
            syncedComponent.props.fontSize = component.props.fontSize;

            break;
          case "Disclaimer":
            syncedComponent.component = "OzonDisclaimer";
            syncedComponent.props = {};
            syncedComponent.props.content = component.props.content;
            syncedComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
            syncedComponent.props.fontSize = component.props.fontSize;

            break;
          case "Picture":
            syncedComponent.component = "OzonPicture";
            syncedComponent.props = {};
            syncedComponent.props.src = component.props.src;
            if (component.props.srcMob !== "") {
              syncedComponent.props.srcMob = component.props.srcMob;
            } else {
              syncedComponent.props.srcMob = component.props.src;
            }
            break;
          case "Grid":
            syncedComponent.component = "OzonGrid";
            syncedComponent.props = {};
            syncedComponent.props = {
              descriptionContent: component.props.descriptionContent,
              direction: component.props.direction,
              src: component.props.innerElements[0].props.src,
              srcMob: component.props.innerElements[0].props.src,
              titleContent: component.props.titleContent,
              titleTextAlign: component.props.titleTextAlign === 'justify' ? 'left' : component.props.titleTextAlign,
              descriptionTextAlign: component.props.descriptionTextAlign === 'justify' ? 'left' : component.props.descriptionTextAlign,
              titleFontSize: component.props.titleFontSize,
              descriptionFontSize: component.props.descriptionFontSize,
            };
            if (component.props.youtubeID && component.props.youtubeID.trim() !== "") {
              let syncedInnerComponent = {};
              syncedInnerComponent.component = "OzonYoutube";
              syncedInnerComponent.props = {};
              syncedInnerComponent.props.youtubeID = component.props.youtubeID;
              syncedComponents.push(syncedInnerComponent);
            } else if (component.props.videoSrc && component.props.videoSrc.trim() !== "") {
              let syncedInnerComponent = {};
              syncedInnerComponent.component = "OzonVideo";
              syncedInnerComponent.props = {};
              syncedInnerComponent.props.src = component.props.videoSrc;
              syncedComponents.push(syncedInnerComponent);
            }
            break;
          case "Youtube":
            syncedComponent.component = "OzonYoutube";
            syncedComponent.props = {};
            syncedComponent.props.youtubeID = component.props.youtubeID;
            break;
          case "Video":
            syncedComponent.component = "OzonVideo";
            syncedComponent.props = {};
            syncedComponent.props.src = component.props.src;
            break;
          case "Icons":
            syncedComponent.component = "OzonIcons";
            syncedComponent.props = {};
            syncedComponent.props.innerElements = [];
            syncedComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
            syncedComponent.props.titleFontSize = component.props.titleFontSize;
            syncedComponent.props.descriptionFontSize = component.props.descriptionFontSize;
            component.props.innerElements.map(innerElement => {
              syncedComponent.props.innerElements.push({
                component: "Icon",
                props: {
                  descriptionContent: innerElement.props.descriptionContent,
                  src: innerElement.props.src,
                  srcMob: innerElement.props.src,
                  titleContent: innerElement.props.titleContent,
                },
              });
            });
            break;
          case "Col2":
            syncedComponent.component = "OzonCol2";
            syncedComponent.props = {};
            syncedComponent.props.innerElements = [];
            syncedComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
            syncedComponent.props.titleFontSize = component.props.titleFontSize;
            syncedComponent.props.descriptionFontSize = component.props.descriptionFontSize;
            component.props.innerElements.map(innerElement => {
              if (!innerElement.props.videoSrc) {
                innerElement.props.videoSrc = ''
              }
              if (!innerElement.props.youtubeID) {
                innerElement.props.youtubeID = ''
              }
              syncedComponent.props.innerElements.push({
                component: "Col",
                props: {
                  titleContent: innerElement.props.titleContent,
                  descriptionContent: innerElement.props.descriptionContent,
                  src: (innerElement.props.videoSrc === '' && innerElement.props.youtubeID === '') ? innerElement.props.src : '/placeholders/empty.png',
                  srcMob: innerElement.props.src,
                  imgLink: '',
                },
              });
            });
            break;
          case "Col3":
            syncedComponent.component = "OzonCol3";
            syncedComponent.props = {};
            syncedComponent.props.innerElements = [];
            syncedComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
            syncedComponent.props.titleFontSize = component.props.titleFontSize;
            syncedComponent.props.descriptionFontSize = component.props.descriptionFontSize;
            component.props.innerElements.map(innerElement => {
              if (!innerElement.props.videoSrc) {
                innerElement.props.videoSrc = ''
              }
              if (!innerElement.props.youtubeID) {
                innerElement.props.youtubeID = ''
              }
              syncedComponent.props.innerElements.push({
                component: "Col",
                props: {
                  titleContent: innerElement.props.titleContent,
                  descriptionContent: innerElement.props.descriptionContent,
                  src: (innerElement.props.videoSrc === '' && innerElement.props.youtubeID === '') ? innerElement.props.src : '/placeholders/empty.png',
                  srcMob: innerElement.props.src,
                  imgLink: '',
                },
              });
            });
            break;
          case "Col4":
            syncedComponent.component = "OzonCol4";
            syncedComponent.props = {};
            syncedComponent.props.innerElements = [];
            syncedComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
            syncedComponent.props.titleFontSize = component.props.titleFontSize;
            syncedComponent.props.descriptionFontSize = component.props.descriptionFontSize;
            component.props.innerElements.map(innerElement => {
              if (!innerElement.props.videoSrc) {
                innerElement.props.videoSrc = ''
              }
              if (!innerElement.props.youtubeID) {
                innerElement.props.youtubeID = ''
              }
              syncedComponent.props.innerElements.push({
                component: "Col",
                props: {
                  titleContent: innerElement.props.titleContent,
                  descriptionContent: innerElement.props.descriptionContent,
                  src: (innerElement.props.videoSrc === '' && innerElement.props.youtubeID === '') ? innerElement.props.src : '/placeholders/empty.png',
                  srcMob: innerElement.props.src,
                  imgLink: '',
                },
              });
            });
            break;
          case "Specs":
            syncedComponent.component = "OzonSpecs";
            syncedComponent.props = {};
            syncedComponent.props.innerElements = [];
            component.props.innerElements.map(innerElement => {
              syncedComponent.props.innerElements.push({
                component: "Spec",
                props: {
                  titleContent: innerElement.props.titleContent,
                  descriptionContent: innerElement.props.descriptionContent,
                },
              });
            });
            break;
          case "Slider":
            if (component.props.innerElements.length) {
              let syncedComponent = {};
              syncedComponent.props = {};
              component.props.innerElements.forEach(innerElement => {
                if (innerElement.props.titleContent.trim() !== "") {
                  let syncedInnerComponent = {};
                  syncedInnerComponent.component = "OzonTitle";
                  syncedInnerComponent.props = {};
                  syncedInnerComponent.props.content = innerElement.props.titleContent;
                  syncedInnerComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
                  syncedInnerComponent.props.fontSize = component.props.titleFontSize,

                    syncedComponents.push(syncedInnerComponent);
                }
                if (innerElement.props.descriptionContent.trim() !== "") {
                  let syncedInnerComponent = {};
                  syncedInnerComponent.component = "OzonDescription";
                  syncedInnerComponent.props = {};
                  syncedInnerComponent.props.content = innerElement.props.descriptionContent;
                  syncedInnerComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
                  syncedInnerComponent.props.fontSize = component.props.descriptionFontSize,
                    syncedComponents.push(syncedInnerComponent);
                }
                if (innerElement.props.youtubeID.trim() !== "") {
                  let syncedInnerComponent = {};
                  syncedInnerComponent.component = "OzonYoutube";
                  syncedInnerComponent.props = {};
                  syncedInnerComponent.props.youtubeID = innerElement.props.youtubeID;
                  syncedComponents.push(syncedInnerComponent);
                } else if (innerElement.props.videoSrc.trim() !== "") {
                  let syncedInnerComponent = {};
                  syncedInnerComponent.component = "OzonVideo";
                  syncedInnerComponent.props = {};
                  syncedInnerComponent.props.src = innerElement.props.videoSrc;
                  syncedComponents.push(syncedInnerComponent);
                } else {
                  let syncedInnerComponent = {};
                  syncedInnerComponent.component = "OzonPicture";
                  syncedInnerComponent.props = {};

                  syncedInnerComponent.props.src = innerElement.props.src;
                  if (innerElement.props.srcMob !== "") {
                    syncedInnerComponent.props.srcMob = innerElement.props.srcMob;
                  } else {
                    syncedInnerComponent.props.srcMob = innerElement.props.src;
                  }
                  // syncedInnerComponent.props.srcMob = innerElement.props.srcMob;
                  syncedComponents.push(syncedInnerComponent);
                }
                if (innerElement.props.disclaimerContent.trim() !== "") {
                  let syncedInnerComponent = {};
                  syncedInnerComponent.component = "OzonDisclaimer";
                  syncedInnerComponent.props = {};
                  syncedInnerComponent.props.content = innerElement.props.disclaimerContent;
                  syncedInnerComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
                  syncedInnerComponent.props.fontSize = component.props.disclaimerFontSize;
                  syncedComponents.push(syncedInnerComponent);
                }
              });
            }
            break;
          case "Bubbles":
            if(component.props) {
              let syncedInnerComponent = {};
              syncedInnerComponent.component = "OzonPicture";
              syncedInnerComponent.props = {};
              syncedInnerComponent.props.src = component.props.src;
              if (component.props.srcMob && component.props.srcMob !== "") {
                syncedInnerComponent.props.srcMob = component.props.srcMob;
              } else {
                syncedInnerComponent.props.srcMob = component.props.src;
              }
              syncedComponents.push(syncedInnerComponent);

              if (component.props.innerElements.length) {
                component.props.innerElements.forEach(innerElement => {
                  if (innerElement.props.titleContent.trim() !== "") {
                    let syncedInnerComponent = {};
                    syncedInnerComponent.component = "OzonTitle";
                    syncedInnerComponent.props = {};
                    syncedInnerComponent.props.content = innerElement.props.titleContent;
                    syncedInnerComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
                    syncedInnerComponent.props.fontSize = component.props.titleFontSize,
                      syncedComponents.push(syncedInnerComponent);
                  }
                  if (innerElement.props.descriptionContent.trim() !== "") {
                    let syncedInnerComponent = {};
                    syncedInnerComponent.component = "OzonDescription";
                    syncedInnerComponent.props = {};
                    syncedInnerComponent.props.content = innerElement.props.descriptionContent;
                    syncedInnerComponent.props.textAlign = component.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
                    syncedInnerComponent.props.fontSize = component.props.descriptionFontSize,
                      syncedComponents.push(syncedInnerComponent);
                  }
                });
              }
            }
            break;
          case "PictureWithText":
            if(component.props) {
              let syncedInnerComponent = {};
              syncedInnerComponent.component = "OzonPicture";
              syncedInnerComponent.props = {};
              syncedInnerComponent.props.src = component.props.src;
              if (component.props.srcMob && component.props.srcMob !== "") {
                syncedInnerComponent.props.srcMob = component.props.srcMob;
              } else {
                syncedInnerComponent.props.srcMob = component.props.src;
              }
              syncedComponents.push(syncedInnerComponent);

              if (component.props.innerElements.length) {
                component.props.innerElements.forEach(innerElement => {
                  if (innerElement.props.titleContent.trim() !== "") {
                    let syncedInnerComponent = {};
                    syncedInnerComponent.component = "OzonTitle";
                    syncedInnerComponent.props = {};
                    syncedInnerComponent.props.content = innerElement.props.titleContent;
                    syncedInnerComponent.props.textAlign = innerElement.props.textAlign === 'justify' ? 'left' : innerElement.props.textAlign;
                    syncedInnerComponent.props.fontSize = innerElement.props.titleFontSize,
                      syncedComponents.push(syncedInnerComponent);
                  }
                  if (innerElement.props.descriptionContent.trim() !== "") {
                    let syncedInnerComponent = {};
                    syncedInnerComponent.component = "OzonDescription";
                    syncedInnerComponent.props = {};
                    syncedInnerComponent.props.content = innerElement.props.descriptionContent;
                    syncedInnerComponent.props.textAlign = innerElement.props.textAlign === 'justify' ? 'left' : innerElement.props.textAlign;
                    syncedInnerComponent.props.fontSize = innerElement.props.descriptionFontSize,
                      syncedComponents.push(syncedInnerComponent);
                  }
                });
              }
            }
            break;
          case "VideoWithText":
            if(component.props) {
              let syncedInnerComponent = {};
              syncedInnerComponent.component = "OzonVideo";
              syncedInnerComponent.props = {};
              syncedInnerComponent.props.src = component.props.videoSrc;

              syncedComponents.push(syncedInnerComponent);

              if (component.props.innerElements.length) {
                component.props.innerElements.forEach(innerElement => {
                  if (innerElement.props.titleContent.trim() !== "") {
                    let syncedInnerComponent = {};
                    syncedInnerComponent.component = "OzonTitle";
                    syncedInnerComponent.props = {};
                    syncedInnerComponent.props.content = innerElement.props.titleContent;
                    syncedInnerComponent.props.textAlign = innerElement.props.textAlign === 'justify' ? 'left' : innerElement.props.textAlign;
                    syncedInnerComponent.props.fontSize = innerElement.props.titleFontSize,
                      syncedComponents.push(syncedInnerComponent);
                  }
                  if (innerElement.props.descriptionContent.trim() !== "") {
                    let syncedInnerComponent = {};
                    syncedInnerComponent.component = "OzonDescription";
                    syncedInnerComponent.props = {};
                    syncedInnerComponent.props.content = innerElement.props.descriptionContent;
                    syncedInnerComponent.props.textAlign = innerElement.props.textAlign === 'justify' ? 'left' : innerElement.props.textAlign;
                    syncedInnerComponent.props.fontSize = innerElement.props.descriptionFontSize,
                      syncedComponents.push(syncedInnerComponent);
                  }
                });
              }
            }
            break;
          case "Accordion":
            if (component.props.src) {
              let syncedInnerComponent = {};
              syncedInnerComponent.component = "OzonPicture";
              syncedInnerComponent.props = {};
              syncedInnerComponent.props.src = component.props.src;
              syncedInnerComponent.props.srcMob = component.props.src;
              syncedComponents.push(syncedInnerComponent);
            }
            if (component.props.innerElements.length) {
              component.props.innerElements.forEach(innerElement => {
                if (innerElement.props.imgPosition === 'right' || innerElement.props.imgPosition === 'left') {
                  let syncedInnerComponent = {};
                  syncedInnerComponent.component = "OzonGrid";
                  syncedInnerComponent.props = {};
                  syncedInnerComponent.props = {
                    descriptionContent: innerElement.props.descriptionContent,
                    direction: innerElement.props.imgPosition,
                    src: innerElement.props.src,
                    srcMob: innerElement.props.src,
                    titleContent: innerElement.props.titleContent,
                    titleTextAlign: innerElement.props.textAlign === 'justify' ? 'left' : innerElement.props.textAlign,
                    descriptionTextAlign: innerElement.props.textAlign === 'justify' ? 'left' : innerElement.props.textAlign,
                    titleFontSize: innerElement.props.titleFontSize,
                    descriptionFontSize: innerElement.props.descriptionFontSize,
                  };
                  syncedComponents.push(syncedInnerComponent);
                } else if (innerElement.props.imgPosition === 'top') {
                  let syncedInnerComponent = {};
                  syncedInnerComponent.component = "OzonPicture";
                  syncedInnerComponent.props = {};
                  syncedInnerComponent.props.src = innerElement.props.src;
                  syncedInnerComponent.props.srcMob = innerElement.props.src;
                  syncedComponents.push(syncedInnerComponent);
                  if (innerElement.props.titleContent.trim() !== "") {
                    let syncedInnerComponent = {};
                    syncedInnerComponent.component = "OzonTitle";
                    syncedInnerComponent.props = {};
                    syncedInnerComponent.props.content = innerElement.props.titleContent;
                    syncedInnerComponent.props.textAlign = innerElement.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
                    syncedInnerComponent.props.fontSize = innerElement.props.titleFontSize,
                      syncedComponents.push(syncedInnerComponent);
                  }
                  if (innerElement.props.descriptionContent.trim() !== "") {
                    let syncedInnerComponent = {};
                    syncedInnerComponent.component = "OzonDescription";
                    syncedInnerComponent.props = {};
                    syncedInnerComponent.props.content = innerElement.props.descriptionContent;
                    syncedInnerComponent.props.textAlign = innerElement.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
                    syncedInnerComponent.props.fontSize = innerElement.props.descriptionFontSize,
                      syncedComponents.push(syncedInnerComponent);
                  }

                } else if (innerElement.props.imgPosition === 'bottom') {
                  if (innerElement.props.titleContent.trim() !== "") {
                    let syncedInnerComponent = {};
                    syncedInnerComponent.component = "OzonTitle";
                    syncedInnerComponent.props = {};
                    syncedInnerComponent.props.content = innerElement.props.titleContent;
                    syncedInnerComponent.props.textAlign = innerElement.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
                    syncedInnerComponent.props.fontSize = innerElement.props.titleFontSize,
                      syncedComponents.push(syncedInnerComponent);
                  }
                  if (innerElement.props.descriptionContent.trim() !== "") {
                    let syncedInnerComponent = {};
                    syncedInnerComponent.component = "OzonDescription";
                    syncedInnerComponent.props = {};
                    syncedInnerComponent.props.content = innerElement.props.descriptionContent;
                    syncedInnerComponent.props.textAlign = innerElement.props.textAlign === 'justify' ? 'left' : component.props.textAlign;
                    syncedInnerComponent.props.fontSize = innerElement.props.descriptionFontSize === 'justify' ? 'left' : innerElement.props.descriptionFontSize,
                      syncedComponents.push(syncedInnerComponent);
                  }
                  let syncedInnerComponent = {};
                  syncedInnerComponent.component = "OzonPicture";
                  syncedInnerComponent.props = {};
                  syncedInnerComponent.props.src = innerElement.props.src;
                  syncedInnerComponent.props.srcMob = innerElement.props.src;
                  syncedComponents.push(syncedInnerComponent);
                }
                if (innerElement.props.disclaimerContent.trim() !== "") {
                  let syncedInnerComponent = {};
                  syncedInnerComponent.component = "OzonDisclaimer";
                  syncedInnerComponent.props = {};
                  syncedInnerComponent.props.content = innerElement.props.disclaimerContent;
                  syncedInnerComponent.props.textAlign = innerElement.props.textAlign === 'justify' ? 'left' : innerElement.props.disclaimerTextAlign;
                  syncedInnerComponent.props.fontSize = innerElement.props.descriptionFontSize === 'justify' ? 'left' : innerElement.props.disclaimerFontSize,
                  syncedComponents.push(syncedInnerComponent);
                }
              });
            }
            break;
        }

        if (Object.keys(syncedComponent).length) {
          syncedComponents.push(syncedComponent);
        };
      });
      // const syncedClearComponents = JSON.parse(JSON.stringify(syncedComponents).replace('&shy;', ''));
      newComponents[state.currentLocale][state.currentTemplate] = syncedComponents;
      commit("updateSynkProcess", false);
      commit("updateComponentsData", newComponents);
      commit("updateIsSavedVal", false);


    } else if (state.currentTemplate === "tefal_template") {
      newComponents[state.currentLocale][state.currentTemplate] = dcopy(newComponents[state.currentLocale].master_template);

      newComponents[state.currentLocale][state.currentTemplate].map(component => {
        switch (component.component) {
          case "Title":
            if(component.props.fontFamily) {
              component.props.fontFamily = "DinPro Bold";
            }
            if(component.props.fontWeight) {
              component.props.fontWeight = "400";
            }
            break;
          case "Description":
          case "Disclaimer":
            if(component.props.fontFamily) {
              component.props.fontFamily = "DinPro Regular";
            }
            if(component.props.fontWeight) {
              component.props.fontWeight = "400";
            }
            if(component.props.fontSize) {
              component.props.fontSize = 1;
            }
            break;
          case "Col2":
          case "Col3":
          case "Col4":
            component.component= "Col3";
            component.props.textAlign = "center";
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "DinPro Bold";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "DinPro Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.titleFontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            if(component.props.titleFontSize) {
              component.props.titleFontSize = 2;
            }
            if(component.props.descriptionFontSize) {
              component.props.descriptionFontSize = 1;
            }

            break;

          case "Grid":
          case "PictureWithText":
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "DinPro Bold";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "DinPro Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.titleFontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            break;

          case "Slider":
            component.props.textAlign = "center";
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "DinPro Bold";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "DinPro Regular";
            }
            if(component.props.disclaimerFontFamily) {
              component.props.disclaimerFontFamily = "DinPro Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.fontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            if(component.props.disclaimerFontWeight) {
              component.props.disclaimerFontWeight = "400";
            }
            if(component.props.disclaimerFontSize) {
              component.props.disclaimerFontSize = 1;
            }
            break;
        }
      });
      commit("updateSynkProcess", false);
      commit("updateComponentsData", newComponents);
      commit("updateIsSavedVal", false);
    } else if (state.currentTemplate === "rowenta_template") {
      newComponents[state.currentLocale][state.currentTemplate] = dcopy(newComponents[state.currentLocale].master_template);
      newComponents[state.currentLocale][state.currentTemplate].map((component) => {
        switch (component.component) {
          case "Title":
            component.props.fontFamily = "Gotham Pro Bold";
            component.props.fontWeight = "400";
            component.props.fontSize = 2;
            component.props.textAlign = "center";
            break;

          case "Description":
          case "Disclaimer":
              component.props.fontFamily = "Gotham Pro Regular";
              component.props.fontWeight = "400";
              component.props.textAlign = "left";
              component.props.fontSize = 1;
            break;
          case "Col2":
          case "Col3":
          case "Col4":
            component.component= "Icons";
            component.props.textAlign = "left";
            component.props.titleFontFamily = "Gotham Pro Bold";
            component.props.descriptionFontFamily = "Gotham Pro Regular";
            component.props.titleFontWeight = "400";
            component.props.descriptionFontWeight = "400";
            component.props.titleFontSize = 1;
            component.props.descriptionFontSize = 1;
            break;
          case "Grid":
          case "PictureWithText":
            component.props.titleFontFamily = "Gotham Pro Bold";
            component.props.descriptionFontFamily = "Gotham Pro Regular";
            component.props.titleFontWeight = "400";
            component.props.descriptionFontWeight = "400";
            break;

          case "Slider":
            component.props.textAlign = "center";
              component.props.titleFontFamily = "Gotham Pro Bold";
              component.props.descriptionFontFamily = "Gotham Pro Regular";
              component.props.disclaimerFontFamily = "Gotham Pro Regular";
              component.props.fontWeight = "400";
              component.props.descriptionFontWeight = "400";
              component.props.disclaimerFontWeight = "400";
              component.props.disclaimerFontSize = 1;
            break;
        }
      });
      commit("updateSynkProcess", false);
      commit("updateComponentsData", newComponents);
      commit("updateIsSavedVal", false);
    } else if (state.currentTemplate === "moulinex_template") {
      newComponents[state.currentLocale][state.currentTemplate] = dcopy(newComponents[state.currentLocale].master_template);

      newComponents[state.currentLocale][state.currentTemplate].map(component => {
        switch (component.component) {
          case "Title":
            if(component.props.fontFamily) {
              component.props.fontFamily = "FuturaFuturisC Regular";
            }
            if(component.props.fontWeight) {
              component.props.fontWeight = "400";
            }
            break;
          case "Description":
          case "Disclaimer":
            if(component.props.fontFamily) {
              component.props.fontFamily = "VAGRounded Regular";
            }
            if(component.props.fontWeight) {
              component.props.fontWeight = "400";
            }
            if(component.props.fontSize) {
              component.props.fontSize = 1;
            }
            break;
          case "Col2":
          case "Col3":
          case "Col4":
            component.component= "Col3";
            component.props.textAlign = "center";
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "FuturaFuturisC Regular";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "VAGRounded Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.titleFontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            if(component.props.titleFontSize) {
              component.props.titleFontSize = 2;
            }
            if(component.props.descriptionFontSize) {
              component.props.descriptionFontSize = 1;
            }

            break;

          case "Grid":
          case "PictureWithText":
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "FuturaFuturisC Regular";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "VAGRounded Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.titleFontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            break;

          case "Slider":
            component.props.textAlign = "center";
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "FuturaFuturisC Regular";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "VAGRounded Regular";
            }
            if(component.props.disclaimerFontFamily) {
              component.props.disclaimerFontFamily = "VAGRounded Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.fontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            if(component.props.disclaimerFontWeight) {
              component.props.disclaimerFontWeight = "400";
            }
            // if(component.props.titleFontSize) {
            //   component.props.titleFontSize = 2;
            // }
            // if(component.props.descriptionFontSize) {
            //   component.props.descriptionFontSize = 1;
            // }
            if(component.props.disclaimerFontSize) {
              component.props.disclaimerFontSize = 1;
            }
            break;
        }
      });
      commit("updateSynkProcess", false);
      commit("updateComponentsData", newComponents);
      commit("updateIsSavedVal", false);
    } else if (state.currentTemplate === "krups_template") {
      newComponents[state.currentLocale][state.currentTemplate] = dcopy(newComponents[state.currentLocale].master_template);

      newComponents[state.currentLocale][state.currentTemplate].map(component => {
        switch (component.component) {
          case "Title":
            if(component.props.fontFamily) {
              component.props.fontFamily = "Gotham Pro Bold";
            }
            if(component.props.fontWeight) {
              component.props.fontWeight = "400";
            }
            break;
          case "Description":
          case "Disclaimer":
            if(component.props.fontFamily) {
              component.props.fontFamily = "Gotham Pro Regular";
            }
            if(component.props.fontWeight) {
              component.props.fontWeight = "400";
            }
            if(component.props.fontSize) {
              component.props.fontSize = 1;
            }
            break;
          case "Col2":
          case "Col3":
          case "Col4":
            component.component= "Col3";
            component.props.textAlign = "center";
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "Gotham Pro Bold";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "Gotham Pro Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.titleFontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            if(component.props.titleFontSize) {
              component.props.titleFontSize = 2;
            }
            if(component.props.descriptionFontSize) {
              component.props.descriptionFontSize = 1;
            }

            break;

          case "Grid":
          case "PictureWithText":
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "Gotham Pro Bold";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "Gotham Pro Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.titleFontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            break;

          case "Slider":
            component.props.textAlign = "center";
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "Gotham Pro Bold";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "Gotham Pro Regular";
            }
            if(component.props.disclaimerFontFamily) {
              component.props.disclaimerFontFamily = "Gotham Pro Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.fontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            if(component.props.disclaimerFontWeight) {
              component.props.disclaimerFontWeight = "400";
            }
            // if(component.props.titleFontSize) {
            //   component.props.titleFontSize = 2;
            // }
            // if(component.props.descriptionFontSize) {
            //   component.props.descriptionFontSize = 1;
            // }
            if(component.props.disclaimerFontSize) {
              component.props.disclaimerFontSize = 1;
            }
            break;
        }
      });
      commit("updateSynkProcess", false);
      commit("updateComponentsData", newComponents);
      commit("updateIsSavedVal", false);
    } else if (state.currentTemplate === "wmf_template") {
      newComponents[state.currentLocale][state.currentTemplate] = dcopy(newComponents[state.currentLocale].master_template);

      newComponents[state.currentLocale][state.currentTemplate].map(component => {
        switch (component.component) {
          case "Title":
            if(component.props.fontFamily) {
              component.props.fontFamily = "Rotis Semi Sans Bold";
            }
            if(component.props.fontWeight) {
              component.props.fontWeight = "400";
            }
            break;
          case "Description":
          case "Disclaimer":
            if(component.props.fontFamily) {
              component.props.fontFamily = "Rotis Semi Sans Regular";
            }
            if(component.props.fontWeight) {
              component.props.fontWeight = "400";
            }
            if(component.props.fontSize) {
              component.props.fontSize = 1;
            }
            break;
          case "Col2":
          case "Col3":
          case "Col4":
            component.component= "Col3";
            component.props.textAlign = "center";
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "Rotis Semi Sans Bold";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "Rotis Semi Sans Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.titleFontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            if(component.props.titleFontSize) {
              component.props.titleFontSize = 2;
            }
            if(component.props.descriptionFontSize) {
              component.props.descriptionFontSize = 1;
            }

            break;

          case "Grid":
          case "PictureWithText":
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "Rotis Semi Sans Bold";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "Rotis Semi Sans Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.titleFontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            break;

          case "Slider":
            component.props.textAlign = "center";
            if(component.props.titleFontFamily) {
              component.props.titleFontFamily = "Rotis Semi Sans Bold";
            }
            if(component.props.descriptionFontFamily) {
              component.props.descriptionFontFamily = "Rotis Semi Sans Regular";
            }
            if(component.props.disclaimerFontFamily) {
              component.props.disclaimerFontFamily = "Rotis Semi Sans Regular";
            }
            if(component.props.titleFontWeight) {
              component.props.fontWeight = "400";
            }
            if(component.props.descriptionFontWeight) {
              component.props.descriptionFontWeight = "400";
            }
            if(component.props.disclaimerFontWeight) {
              component.props.disclaimerFontWeight = "400";
            }
            // if(component.props.titleFontSize) {
            //   component.props.titleFontSize = 2;
            // }
            // if(component.props.descriptionFontSize) {
            //   component.props.descriptionFontSize = 1;
            // }
            if(component.props.disclaimerFontSize) {
              component.props.disclaimerFontSize = 1;
            }
            break;
        }
      });
      commit("updateSynkProcess", false);
      commit("updateComponentsData", newComponents);
      commit("updateIsSavedVal", false);
    } else if (state.currentTemplate === "img_template") {
      commit("setCurrentTemplate", "master_template");
      commit("updateLoading", true);
      commit("updateSynkProcess", true);
      const target = document.getElementById("stream-container");

      const config = {
        childList: true,
      };
      let mutationCount = 0;
      // const componentsTotal = newComponents.master_template.length;
      if (newComponents[state.currentLocale].img_template === undefined) {
        newComponents[state.currentLocale].img_template = [];
      }
      const componentsCount = newComponents[state.currentLocale].img_template.length + newComponents[state.currentLocale].master_template.length;

      const callback = function (mutationsList, observer) {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            mutationCount++;

            if (mutationCount === componentsCount) {
              const imgs = document.querySelectorAll("#stream-container img");
              let imgCounter = 0;
              let imgUploadedCounter = 0;
              if (imgs.length) {
                imgs.forEach(img => {
                  img.onload = () => {
                    imgCounter++;
                    if (imgCounter === imgs.length) {
                      const components = document.querySelectorAll("#stream-container > div:not(.stream-video,.stream-youtube__video,.stream-slider,.stream-bubbles");
                      if (components.length > 0) {
                        newComponents[state.currentLocale].img_template = [];
                        const syncedComponents = [];
                        components.forEach((component, index) => {
                          const syncedComponent = {};
                          htmlToImage.toPng(component)
                            .then(function (dataUrl) {
                              const parts = dataUrl.split(",");
                              const type = parts[0];
                              const data = parts[1];
                              const img = b64toBlob(data, type);
                              let formData = new FormData();
                              formData.append("file", img);
                              APIConstructor.APIPost(
                                `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/load/img`,
                                formData,
                                (response) => {
                                  if (response.code === 200) {
                                    imgUploadedCounter++;
                                    const img = response.data;
                                    syncedComponent.component = "ImgPicture";
                                    syncedComponent.props = {};
                                    syncedComponent.props.src = img;
                                    syncedComponent.props.srcMob = img;
                                    if (Object.keys(syncedComponent).length) {
                                      syncedComponents[index] = syncedComponent;
                                    };

                                    // components
                                    // if(imgUploadedCounter === componentsTotal) {
                                    if (imgUploadedCounter === components.length) {
                                      observer.disconnect();
                                      commit("setCurrentTemplate", "img_template");
                                      newComponents[state.currentLocale][state.currentTemplate] = syncedComponents;
                                      commit("updateComponentsData", newComponents);
                                      commit("updateIsSavedVal", false);
                                      commit("updateLoading", false);
                                      commit("updateSynkProcess", false);
                                    }
                                  }
                                },
                                (error) => {
                                  if (error.response.status === 402) {
                                    showToastError(
                                      error.response.data.data.message,
                                      this._vm.$toast,
                                    );

                                  }
                                }
                              );
                            })
                            .catch(function (error) {
                              console.error(error);
                            });
                        });
                      } else {
                        commit("setCurrentTemplate", "img_template");
                        commit("updateLoading", false);
                        commit("updateSynkProcess", false);
                      }
                    }
                  }
                });
              } else {
                const components = document.querySelectorAll("#stream-container > div:not(.stream-video,.stream-youtube__video,.stream-slider,.stream-bubbles");
                if (components.length > 0) {
                  newComponents[state.currentLocale].img_template = [];
                  const syncedComponents = [];

                  components.forEach((component, index) => {
                    const syncedComponent = {};
                    htmlToImage.toPng(component)
                      .then(function (dataUrl) {
                        const parts = dataUrl.split(",");
                        const type = parts[0];
                        const data = parts[1];
                        const img = b64toBlob(data, type);
                        let formData = new FormData();
                        formData.append("file", img);
                        APIConstructor.APIPost(
                          `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/load/img`,
                          formData,
                          (response) => {
                            if (response.code === 200) {
                              imgUploadedCounter++;
                              const img = response.data;
                              syncedComponent.component = "ImgPicture";
                              syncedComponent.props = {};
                              syncedComponent.props.src = img;
                              syncedComponent.props.srcMob = img;
                              if (Object.keys(syncedComponent).length) {
                                syncedComponents[index] = syncedComponent;
                              };

                              // components
                              // if(imgUploadedCounter === componentsTotal) {
                              if (imgUploadedCounter === components.length) {
                                observer.disconnect();
                                commit("setCurrentTemplate", "img_template");
                                newComponents[state.currentLocale][state.currentTemplate] = syncedComponents;
                                commit("updateComponentsData", newComponents);
                                commit("updateIsSavedVal", false);
                                commit("updateLoading", false);
                                commit("updateSynkProcess", false);
                              }
                            }
                          },
                          (error) => {
                            if (error.response.status === 402) {
                              showToastError(
                                error.response.data.data.message,
                                this._vm.$toast,
                              );

                            }
                          }
                        );
                      })
                      .catch(function (error) {
                        console.error(error);
                      });
                  });
                } else {
                  commit("setCurrentTemplate", "img_template");
                  commit("updateLoading", false);
                  commit("updateSynkProcess", false);
                }
              }


            }

          }

        }
      };
      const observer = new MutationObserver(callback);
      observer.observe(target, config);
    } else if (state.currentTemplate === "eld_template") {
      const syncedComponents = dcopy(state.epackData.components[state.currentLocale].master_template);
      const eldSyncedComponents = syncedComponents.map(component => {
        return {
          component: 'Eld' + component.component,
          props: component.props,
        }
      });
      newComponents[state.currentLocale][state.currentTemplate] = eldSyncedComponents;
      commit("updateSynkProcess", false);
      commit("updateComponentsData", newComponents);
      commit("updateIsSavedVal", false);
    } else {
      const syncedComponents = dcopy(state.epackData.components[state.currentLocale].master_template);
      newComponents[state.currentLocale][state.currentTemplate] = syncedComponents;
      commit("updateSynkProcess", false);
      commit("updateComponentsData", newComponents);
      commit("updateIsSavedVal", false);
    };
  },
  changeAuthorMass({ commit }, { author, epacksList }) {
    commit("updateLoading", true);
    APIConstructor.APIPost(
      `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/epackage_author`,
      {
        author,
        epackages: epacksList,
      },
      (response) => {
        if (response.code === 200) {
          showToastSuccess(
            "Updated",
            this._vm.$toast,
          );
        }
      },
      (error) => {
        if (error.response.status === 402 || error.response.status === 404) {
          showToastError(
            error.response.data.data.message,
            this._vm.$toast,
          );
        } else {
          showToastError(
            "Something wrong. <br/> Please try later",
            this._vm.$toast,
          );
        }
        throw new Error('Something went wrong');
      }
    );
    commit("updateLoading", false);
  },

  copyLocaleMass({ commit }, { src, dist, epacksList }) {
    commit("updateLoading", true);
    APIConstructor.APIPost(
      `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/locale/create`,
      {
        locale_source: src,
        locale_name: dist,
        epackages: epacksList,
      },
      (response) => {
        if (response.code === 200) {
          showToastSuccess(
            "Copied",
            this._vm.$toast,
          );
        }
      },
      (error) => {
        if (error.response.status === 402 || error.response.status === 404) {
          showToastError(
            error.response.data.data.message,
            this._vm.$toast,
          );
        } else {
          showToastError(
            "Something wrong. <br/> Please try later",
            this._vm.$toast,
          );
        }
        throw new Error('Something went wrong');
      }
    );
    commit("updateLoading", false);
  },
  compressEpack({ state, commit },{epackID,quality}) {
    commit("updateLoading", true);
    APIConstructor.APIPost(
      `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/optimize_image/${epackID}`,
      {components: state.epackData.components, quality},
      (response) => {
        if (response.code === 200) {
          commit("updateComponentsData", response.components);
          commit("updateIsSavedVal", false);
          showToastSuccess(
            "Images compressed",
            this._vm.$toast,
          );
        } else {
          showToastError(
            "Something wrong. <br/> Please try later",
            this._vm.$toast,
          );
        };
        commit("updateLoading", false);
      },
      (error) => {
        if(error?.response?.status) {
          if (error?.response?.status === 402) {
            showToastError(
              `${error.response.data.data.message}`,
              this._vm.$toast,
            );
          } else if (error?.response?.status === 400) {
            const details = JSON.parse(error.response.data.detail);
            showToastError(
              details.data.message,
              this._vm.$toast,
            );
          }
        } else {
          showToastError(
            "Something wrong. <br/> Please try later",
            this._vm.$toast,
          );
        }
        commit("updateLoading", false);
      },
    );
  },
};

function unique(arr) {
  return Array.from(new Set(arr));
};

function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

function addNewProps(components) {
  Object.keys(components).forEach(locale => {
    Object.keys(components[locale]).forEach(template => {
      if (template === 'img_template' && components[locale][template].length) {
      } else if (template === 'ozon_template' && components[locale][template].length) {
        components[locale][template].forEach(component => {
          switch (component.component) {
            case "OzonTitle":
              if (!component.props.fontSize) {
                component.props.fontSize = "4";
              }
              if (component.props.textAlign === undefined || typeof (component.props.textAlign) !== "string") {
                component.props.textAlign = "left";
              } else {
                component.props.textAlign = component.props.textAlign.toLowerCase();
              }
              break;
            case "OzonDescription":
              if (!component.props.fontSize) {
                component.props.fontSize = "2";
              }
              if (component.props.textAlign === undefined || typeof (component.props.textAlign) !== "string") {
                component.props.textAlign = "left";
              } else {
                component.props.textAlign = component.props.textAlign.toLowerCase();
              }
              break;
            case "OzonDisclaimer":
              if (!component.props.fontSize) {
                component.props.fontSize = "1";
              }
              if (component.props.textAlign === undefined || typeof (component.props.textAlign) !== "string") {
                component.props.textAlign = "left";
              } else {
                component.props.textAlign = component.props.textAlign.toLowerCase();
              }
              break;
            case "OzonPicture":
              if (!component.props.imgLink) {
                component.props.imgLink = "";
              }
              break;
            case "OzonGrid":
              if (!component.props.titleFontSize) {
                component.props.titleFontSize = "4";
              }
              if (!component.props.descriptionFontSize) {
                component.props.descriptionFontSize = "2";
              }
              if (!component.props.imgLink) {
                component.props.imgLink = "";
              }
              component.props.titleTextAlign = component.props.titleTextAlign.toLowerCase();
              component.props.descriptionTextAlign = component.props.descriptionTextAlign.toLowerCase();
              break;
            case "OzonIcons":
              if (!component.props.titleFontSize) {
                component.props.titleFontSize = "2";
              }
              if (!component.props.descriptionFontSize) {
                component.props.descriptionFontSize = "XS";
              }
              break;
            case "OzonCol2":
            case "OzonCol3":
            case "OzonCol4":
              if (!component.props.titleFontSize) {
                component.props.titleFontSize = "2";
              }
              if (!component.props.descriptionFontSize) {
                component.props.descriptionFontSize = "2";
              }
              component.props.innerElements.forEach(elem => {
                if (!elem.props.imgLink) {
                  elem.props.imgLink = "";
                }
              });

              break;
          }
        });
      } else if (components[locale][template].length) {
        components[locale][template].forEach(component => {
          switch (component.component) {
            case "Title":
              if (!component.props.fontSize) {
                component.props.fontSize = "4";
              }
              component.props.textAlign = component.props.textAlign.toLowerCase();
              if (!component.props.textAlignMobile) {
                component.props.textAlignMobile = component.props.textAlign;
              }
              component.props.textAlignMobile = component.props.textAlignMobile.toLowerCase();
              break;
            case "Description":
              if (!component.props.fontSize) {
                component.props.fontSize = "2";
              }
              component.props.textAlign = component.props.textAlign.toLowerCase();
              if (!component.props.textAlignMobile) {
                component.props.textAlignMobile = component.props.textAlign;
              }
              component.props.textAlignMobile = component.props.textAlignMobile.toLowerCase();
              break;
            case "Disclaimer":
              if (!component.props.fontSize) {
                component.props.fontSize = "1";
              }
              component.props.textAlign = component.props.textAlign.toLowerCase();
              if (!component.props.textAlignMobile) {
                component.props.textAlignMobile = component.props.textAlign;
              }
              component.props.textAlignMobile = component.props.textAlignMobile.toLowerCase();
              break;
            case "Picture":
            case "EldPicture":
              if (!component.props.srcMob) {
                component.props.srcMob = "";
              }
              if (!component.props.textAlignMobile) {
                component.props.textAlignMobile = component.props.textAlign;
              }
              if (!component.props.background) {
                component.props.background = '#ffffff';
              }
              if (!component.props.borderRadius) {
                component.props.borderRadius = '0';
              }
              break;

            case "Grid":
            case "EldGrid":
              if (!component.props.titleFontSize) {
                component.props.titleFontSize = "4";
              }
              if (!component.props.descriptionFontSize) {
                component.props.descriptionFontSize = "2";
              }
              if (!component.props.youtubeID) {
                component.props.youtubeID = "";
              }
              if (!component.props.videoSrc) {
                component.props.videoSrc = "";
              }
              if (!component.props.customArrow) {
                component.props.customArrow = "";
              }
              if (!component.props.dotsColor) {
                component.props.dotsColor = "#333333";
              }
              if (!component.props.activeDotColor) {
                component.props.activeDotColor = "#16c7dc";
              }
              if (!component.props.arrowsColor) {
                component.props.arrowsColor = "#000000";
              }
              if (!component.props.imgColWidth) {
                component.props.imgColWidth = "50%";
              }
              if (!component.props.borderRadius) {
                component.props.borderRadius = '0';
              }
              if (!component.props.innerElements) {
                component.props.innerElements = [
                  {
                    component: "Item",
                    props: {
                      src: component.props.src,
                      youtubeID: !component.props.youtubeID ? "" : component.props.youtubeID,
                      videoSrc: !component.props.videoSrc ? "" : component.props.videoSrc,
                      autoplay: !component.props.autoplay ? "false" : component.props.autoplay,
                      repeat: !component.props.repeat ? "false" : component.props.repeat,
                    },
                  },
                ];
              }
              component.props.innerElements.forEach(elem => {
                if (!elem.props.srcMob) {
                  elem.props.srcMob = "";
                }
                if (!elem.props.repeat) {
                  elem.props.repeat = 'false';
                }
                if (!elem.props.autoplay) {
                  elem.props.autoplay = 'false';
                }
                if (!elem.props.controls) {
                  elem.props.controls = 'true';
                }
              });
              if (!component.props.titleTextAlignMobile) {
                component.props.titleTextAlignMobile = component.props.titleTextAlign;
              }
              component.props.titleTextAlign = component.props.titleTextAlign.toLowerCase();
              component.props.titleTextAlignMobile = component.props.titleTextAlignMobile.toLowerCase();
              if (!component.props.descriptionTextAlignMobile) {
                component.props.descriptionTextAlignMobile = component.props.descriptionTextAlign;
              }
              component.props.descriptionTextAlign = component.props.descriptionTextAlign.toLowerCase();
              component.props.descriptionTextAlignMobile = component.props.descriptionTextAlignMobile.toLowerCase();

              if (component.props.youtubeID) {
                delete component.props.youtubeID;
              }
              if (component.props.videoSrc) {
                delete component.props.videoSrc;
              }
              if (component.props.src) {
                delete component.props.src;
              }
              break;
            case "Slider":
            case "EldSlider":
              if (!component.props.titleFontSize) {
                component.props.titleFontSize = "4";
              }
              if (!component.props.descriptionFontSize) {
                component.props.descriptionFontSize = "2";
              }
              if (!component.props.disclaimerFontSize) {
                component.props.disclaimerFontSize = "1";
              }
              if (!component.props.activeDotColor) {
                component.props.activeDotColor = "#16c7dc";
              }
              if (!component.props.textAlignMobile) {
                component.props.textAlignMobile = component.props.textAlign;
              }
              if (!component.props.borderRadius) {
                component.props.borderRadius = '0';
              }
              component.props.innerElements.forEach(elem => {
                if (!elem.props.alignItems) {
                  elem.props.alignItems = 'flex-start';
                }
                if (!elem.props.descriptionColor) {
                  elem.props.descriptionColor = elem.props.color;
                }
                if (!elem.props.titleColor) {
                  elem.props.titleColor = elem.props.color;
                }
                if (!elem.props.disclaimerColor) {
                  elem.props.disclaimerColor = elem.props.color;
                }
                if (!elem.props.videoSrc) {
                  elem.props.videoSrc = "";
                }
                if (!elem.props.poster) {
                  elem.props.poster = "";
                }
                if (!elem.props.repeat) {
                  elem.props.repeat = 'false';
                }
                if (!elem.props.autoplay) {
                  elem.props.autoplay = 'false';
                }
                if (!elem.props.controls) {
                  elem.props.controls = 'true';
                }
              });
              break;
            case "Icons":
            case "EldIcons":
              if (!component.props.titleFontSize) {
                component.props.titleFontSize = "2";
              }
              if (!component.props.descriptionFontSize) {
                component.props.descriptionFontSize = "1";
              }
              if (!component.props.descriptionColor) {
                component.props.descriptionColor = component.props.color;
              }
              if (!component.props.titleColor) {
                component.props.titleColor = component.props.color;
              }
              if (!component.props.textAlignMobile) {
                component.props.textAlignMobile = component.props.textAlign;
              }
              if (!component.props.colsAlign) {
                component.props.colsAlign = "flex-start";
              }
              if (!component.props.borderRadius) {
                component.props.borderRadius = '0';
              }
              component.props.textAlign = component.props.textAlign.toLowerCase();
              component.props.textAlignMobile = component.props.textAlignMobile.toLowerCase();

              break;
            case "Col2":
            case "EldCol2":
            case "Col3":
            case "EldCol3":
            case "Col4":
            case "EldCol4":
              if (!component.props.titleFontSize) {
                component.props.titleFontSize = "2";
              }
              if (!component.props.descriptionFontSize) {
                component.props.descriptionFontSize = "2";
              }
              if (!component.props.descriptionColor) {
                component.props.descriptionColor = component.props.color;
              }
              if (!component.props.titleColor) {
                component.props.titleColor = component.props.color;
              }
              if (!component.props.textAlignMobile) {
                component.props.textAlignMobile = component.props.textAlign;
              }
              if (!component.props.colsAlign) {
                component.props.colsAlign = "flex-start";
              }
              if (!component.props.borderRadius) {
                component.props.borderRadius = '0';
              }
              if (!component.props.imgWidth) {
                component.props.imgWidth = '';
              }
              component.props.innerElements.forEach(elem => {
                if (!elem.props.repeat) {
                  elem.props.repeat = 'false';
                }
                if (!elem.props.autoplay) {
                  elem.props.autoplay = 'false';
                }
                if (!elem.props.controls) {
                  elem.props.controls = 'true';
                }
                if (!elem.props.youtubeID) {
                  elem.props.youtubeID = "";
                }
                if (!elem.props.videoSrc) {
                  elem.props.videoSrc = "";
                }
              });
              break;
            case "Specs":
            case "EldSpecs":
              if (!component.props.descriptionColor) {
                component.props.descriptionColor = component.props.color;
              }
              if (!component.props.titleColor) {
                component.props.titleColor = component.props.color;
              }
              if (!component.props.titleFontSize) {
                component.props.titleFontSize = '2';
              }
              if (!component.props.descriptionFontSize) {
                component.props.descriptionFontSize = '2';
              }
              if (!component.props.borderWidth) {
                component.props.borderWidth = '1';
              }
              if (!component.props.borderColor) {
                component.props.borderColor = '#dddddd';
              }
              break;
            case "Bubbles":
              component.props.innerElements.forEach(elem => {
                if (!elem.props.textAlignMobile) {
                  elem.props.textAlignMobile = elem.props.textAlign;
                }
              });
              break;
            case "Table":
            case "EldTable":
              if (!component.props.borderWidth) {
                component.props.borderWidth = '1';
              }
              if (!component.props.borderColor) {
                component.props.borderColor = '#dddddd';
              }
              if (!component.props.justifyContent) {
                component.props.justifyContent = 'flex-start';
              }
              if (!component.props.textAlign) {
                component.props.textAlign = 'left';
              }
              component.props.innerElements.forEach(elem => {
                if (!elem.props.rowBackgrounds) {
                  elem.props.rowBackgrounds = [];
                  for (let i = 0; i < component.props.columns; i++) {
                    elem.props.rowBackgrounds.push('');
                  }
                }
                if (!elem.props.rowColors) {
                  elem.props.rowColors = [];
                  for (let i = 0; i < component.props.columns; i++) {
                    elem.props.rowColors.push('');
                  }
                }
              });
              break;
            case "Video":
            case "EldVideo":
              if (!component.props.autoplay) {
                component.props.autoplay = 'false';
              }
              if (!component.props.repeat) {
                component.props.repeat = 'false';
              }
              if (!component.props.controls) {
                component.props.controls = 'true';
              }
              if (!component.props.background) {
                component.props.background = '#ffffff';
              }
              if (!component.props.borderRadius) {
                component.props.borderRadius = '0';
              }
              break;
            case "EldAccordion":
            case "Accordion":
              if (!component.props.autoplay) {
                component.props.autoplay = 'false';
              }
              if (!component.props.repeat) {
                component.props.repeat = 'false';
              }
              if (!component.props.controls) {
                component.props.controls = 'true';
              }
              if (!component.props.youtubeID) {
                component.props.youtubeID = "";
              }
              if (!component.props.videoSrc) {
                component.props.videoSrc = "";
              }
              if (!component.props.background) {
                component.props.background = "#ffffff";
              }
              if (!component.props.customCloseIcon) {
                component.props.customCloseIcon = "";
              }
              if (!component.props.titleFontFamily) {
                component.props.titleFontFamily = component.props.innerElements[0].props.titleFontFamily;
              }
              if (!component.props.titleFontWeight) {
                component.props.titleFontWeight = component.props.innerElements[0].props.titleFontWeight;
              }
              if (!component.props.descriptionFontFamily) {
                component.props.descriptionFontFamily = component.props.innerElements[0].props.descriptionFontFamily;
              }
              if (!component.props.descriptionFontWeight) {
                component.props.descriptionFontWeight = component.props.innerElements[0].props.descriptionFontWeight;
              }
              if (!component.props.borderRadius) {
                component.props.borderRadius = '0';
              }
              component.props.innerElements.forEach(elem => {
                if (!elem.props.srcMob) {
                  elem.props.srcMob = "";
                }
                if (!elem.props.repeat) {
                  elem.props.repeat = 'false';
                }
                if (!elem.props.autoplay) {
                  elem.props.autoplay = 'false';
                }
                if (!elem.props.controls) {
                  elem.props.controls = 'true';
                }
                if (!elem.props.youtubeID) {
                  elem.props.youtubeID = "";
                }
                if (!elem.props.videoSrc) {
                  elem.props.videoSrc = "";
                }
                if (!elem.props.disclaimerContent) {
                  elem.props.disclaimerContent = "";
                }
                if (!elem.props.disclaimerColor) {
                  elem.props.disclaimerColor = "#000000";
                }
                if (!elem.props.disclaimerFontSize) {
                  elem.props.disclaimerFontSize = "1";
                }
                if (!elem.props.disclaimerTextAlign) {
                  elem.props.disclaimerTextAlign = "left";
                }
                delete elem.props.titleFontFamily;
                delete elem.props.titleFontWeight;
                delete elem.props.descriptionFontFamily;
                delete elem.props.descriptionFontWeight;
              });
              break;
            case 'ARBanner':
            case 'EldARBanner':
              if (!component.props.arLocale) {
                component.props.arLocale = 'ru';
              }
              break;

            case 'PictureWithText':
            case 'EldPictureWithText':
              if (!component.props.srcMob) {
                component.props.srcMob = component.props.src;
              }
              component.props.innerElements.forEach(elem => {
                if (!elem.props.descriptionColorMobile) {
                  elem.props.descriptionColorMobile = elem.props.titleColorMobile;
                }
              });
              break;

            case 'Iframe':
              if (!component.props.heightUnits) {
                component.props.heightUnits = 'px';
              }
              break;
            case 'Youtube':
            case 'EldYoutube':
              if (!component.props.background) {
                component.props.background = '#ffffff';
              }
              if (!component.props.borderRadius) {
                component.props.borderRadius = '0';
              }
              break;
          }
        });
      }
    });
  });
  return components;
};

function clearTemplatesInLocales(components) {
  const templates = Object.keys(components).filter((elem) => (
    elem.includes('template')
  ));
  let localesDataCleared = {};
  if (templates.length) {
    localesDataCleared.ru = components;
  } else {
    localesDataCleared = dcopy(components);
  }
  return localesDataCleared;
};

export default actions;
