import axios from 'axios';

const handleThanks = (response, callback, errorCallback) => {
  if (response && response.status === 200) {
    let data = response.data.data ? response.data.data : response.data;
    return callback ? callback(data, response) : data;
  } else {
    return errorCallback ? errorCallback() : [];
  }
};

const handleError = (error, errorCallback) => {
  // if (error.response?.status && error.response.status === 401) {
  //   localStorage.removeItem('accessToken');
  //   localStorage.removeItem('constructorAccessToken');
  //   localStorage.removeItem('ttlUserInfo');
  //   window.location.href = '/auth/login';
  // }

  return errorCallback ? errorCallback(error) : logError(error.message);
};

const logError = (message) => {
  console.error(message);
}

/**
 *
 * @param {string} url
 * @param {object} data
 * @param {function} callback
 * @param {function} errorCallback
 * @deprecated use HelperAPI with param method GET
 */

const APIGet = (url, data, callback, errorCallback = null) => {
  data.headers = {
    Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
  };

  axios.get(url, data)
    .then(response => {
      handleThanks(response, callback, errorCallback);
    })
    .catch(error => {
      handleError(error, errorCallback);
    });
};

/**
 *
 * @param {string} url
 * @param {object} data
 * @param {function} callback
 * @param {function} errorCallback
 * @param {object} headers
 * @param {boolean} withoutAuth
 * @constructor
 * @deprecated use HelperAPI with param method POST
 */

const APIPost = (url, data, callback, errorCallback, headers = {}, withoutAuth = false) => {
  if (!withoutAuth) {
    headers.headers = {
      Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
    };
  }

  axios.post(url, data, headers)
    .then(response => {
      handleThanks(response, callback, errorCallback);
    })
    .catch(error => {
      handleError(error, errorCallback);
    });
};

/**
 *
 * @param {string} url
 * @param {object} data
 * @param {function} callback
 * @param {function} errorCallback
 * @constructor
 * @deprecated use HelperAPI with param method DELETE
 */

const APIDelete = (url, data, callback, errorCallback) => {
  data.headers = { Authorization: `Bearer ${window.localStorage.getItem('accessToken')}` };
  axios.delete(url, data)
    .then(response => {
      handleThanks(response, callback, errorCallback);
    })
    .catch(error => {
      handleError(error, errorCallback);
    });
};

/**
 *
 * @param {string} url
 * @param {object} data
 * @param {function} callback
 * @param {function} [errorCallback]
 * @constructor
 * @deprecated use HelperAPI with param method PUT
 */

const APIPut = (url, data, callback, errorCallback) => {
  const headers = {};
  headers.headers = { Authorization: `Bearer ${window.localStorage.getItem('accessToken')}` };
  axios.put(url, data, headers)
    .then(response => {
      handleThanks(response, callback, errorCallback);
    })
    .catch(error => {
      handleError(error, errorCallback);
    });
};

export default {
  APIGet,
  APIPost,
  APIDelete,
  APIPut,
};
