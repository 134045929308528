const mutations = {
  updateManifestData(state, manifestData) {
    state.epackData.manifest = manifestData;
  },
  updateComponentsData(state, componentsData) {
    state.epackData.components = componentsData;
  },
  updateLocalesData(state, localesData) {
    state.epackData.components = localesData;
  },
  updateActiveComponent(state, componentIndex) {
    state.activeComponent = componentIndex;
  },
  updateActiveElement(state, elementIndex) {
    state.activeElement = elementIndex;
  },
  updateIsSavedVal(state, val) {
    state.isSaved = !!val;
  },
  updateLoading(state, val) {
    state.loading = !!val;
  },
  updateSynkProcess(state, val) {
    state.synkProcess = !!val;
  },
  setCurrentEpackId(state, epackID) {
    state.currentEpackage = epackID;
  },
  setCurrentTemplate(state, template) {
    state.currentTemplate = template;
  },
  setCurrentLocale(state, locale) {
    state.currentLocale = locale;
  },
  updateAvailableFonts(state, fonts) {
    state.availableFonts = fonts;
  },
  updateFontsSources(state, fonts) {
    state.fontsSources = fonts;
  },
  updateUsedFonts(state, fonts) {
    state.epackData.usedFonts = fonts;
  },
  updateUnsavedPopupShow(state, val) {
    state.unsavedPopupShow = !!val;
  },
  updateUnsavedEpackageID(state, val) {
    state.unsavedEpackageID = !!val;
  },
  updateCategoriesList(state, data) {
    state.availableCategoriesList = data;
  },
  updateUsersList(state, data) {
    state.usersList = data;
  },
  setActiveSize(state, type) {
    state.activeSize = state.sizes[type];
  },
  setActiveType(state, type) {
    state.activeType = type;
  },
  setCurrentPage(state, pageNumber) {
    state.currentPage = pageNumber;
  },
  setEpacksPerPage(state, epacksPerPage) {
    state.epacksPerPage = epacksPerPage;
  },
  setEpacksSearch(state, value) {
    state.epacksSearch = value;
  },
  setTemplatesList(state, templatesList) {
    state.templatesList = templatesList;
  },
  setCoversData(state, data) {
    state.epackData.coverData = data;
  },
  setImages(state, data) {
      state.epackData.images = data;
  },
  setLicensesList(state, data) {
    state.licensesList = data;
  },
  setUserBrands(state, brands) {
    state.brands = brands;
  },
};

export default mutations;